<!--渠道费用明细-->
<template>
  <div class="installationFeeDetail bgblue">
    <div class="top"></div>
    <div class="form">
      <el-form
        :model="form"
        class="demo-form-inline"
        style="position: relative"
        label-width="110px"
      >
        <el-row>
          <el-col :span="4"
            ><el-form-item label="业务单据号:">
              <el-input
                v-model="form.user"
                placeholder="请输入业务单据号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="4">
              <el-form-item label="状态:">
                <el-select
                  v-model="explorationStatus.region"
                  placeholder="请选择状态"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in explorationStatus"
                    :key="item.region"
                    :label="item.label"
                    :value="item.region"
                  >
                  </el-option>
                </el-select> </el-form-item
            ></el-col>
          <el-col :span="4"
            ><el-form-item label="支付流程号:">
              <el-input
                v-model="form.user"
                placeholder="请输入支付流程号"
              ></el-input></el-form-item
          ></el-col>
            <el-col :span="4"
              ><el-form-item label="核算名称:">
                <el-input
                v-model="form.user"
                placeholder="请输入核算名称"
              ></el-input> </el-form-item
            ></el-col>
         
            <el-col :span="4">
              <el-form-item label="支付时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="生成时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
         
         
            <el-col :span="4">
              <el-form-item label="确认时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="开票时间:">
                <el-date-picker
                  style="width: 100%"
                  v-model="value1"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          <el-col :span="4" style="margin-left: 50px;">
            <el-button type="primary" size="small">
              <i class="el-icon-search"></i>查询</el-button
            >
            <el-button plain size="small">
              <i class="el-icon-refresh"></i>重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bgwhite">
      <el-row>
        <el-button type="warning" plain size="small">
          导出</el-button
        >
        <el-button type="warning" plain size="small">
       确认</el-button
        >
       
      </el-row>
      <div class="table">
        <el-table
          :data="tableData"
          border
          height="calc(100vh - 300px)"
          :header-cell-style="{ background: '#f2f2f2', color: '#333' }"
        >
          <el-table-column
            prop="companyName"
            label="业务类型"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="customerName" label="客户名称" align="center">
          </el-table-column>
          <el-table-column
            prop="explorateCode"
            label="用户姓名"
            align="center"
            width="250px"
          >
            <template v-slot="{ row }">
              <span style="color: #409eff">{{ row.explorateCode }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="acheiveNum"
            label="组件片数"
            align="center"
          >
            <template slot-scope="scope">
              <span style="color: #409eff">{{ scope.row.exploeNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="agentName"
            label="并网日期"
            align="center"
            width="200px"
          >
          </el-table-column>
          <el-table-column prop="area" label="业务提成单价(元/片)" width="250px" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="业务提成合计(元)" width="150" align="center">
          </el-table-column>
          <el-table-column
            prop="idNumber"
            label="已支付(元)"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="installionState"
            label="待支付(元)"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="cooperationType"
            label="实际支付(元)"
            align="center"
          >
          </el-table-column>
        
       
        
         
        
        
          
          
          <el-table-column
            prop=" "
            label="操作"
          
            align="center"
            fixed
          >
            <template v-slot="{ row }">
              <el-button type="text" class="btn-blue" @click="goDetail(row)"
                >明细</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currPage"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getData();
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    goDetail(row) {
      this.$router.push(
        `/expoleManageDetail?explorateCode=${row.explorateCode}`
      );
    },
    onSubmit() {
      console.log("submit!");
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.params.currPage = val;
      this.getData();
    },
    getData() {
      let data = {
        condition: this.form,
        ...this.params,
      };
      this.$http.post("/houseCustomer/listDetail", data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.tableData = res.data.data.data;
          this.total = res.data.data.count;
        }else{
         this.$message({
          type: 'error', // success error warning
          message: res.data.message,
          duration: 2000,
         })
        }
      });
    },
  },
  data() {
    return {
      tableData: [
      
      ],
      total: 0,
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 20,
      },
      form: {
        dataStatus: 1,
      },
      roofType: [],
      roofNum: [],
      inverterSN: [],
      installType: [],
      explorationStatus: [],
      installationStatus: [],
      results: [],
      securityRejections: [],
      cooperationType: [],
      explorationArea: [],
      firm: [],
      dataType: [],
      securityDocument: [],
      wellPattern: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      currentPage4: 1,
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped >
::v-deep .el-table td {
  padding: 0px !important;
}
::v-deep .el-input__icon{
  line-height: 21px !important;
}
::v-deep .el-table th {
  padding: 0px !important;
}
::v-deep .el-table {
  margin-top: 10px !important;
}
.installationFeeDetail {
  .el-form .el-row .el-col-4 {
    width: 19.5%;
  }
 
  .el-form-item__label {
    font-size: 13px;
  }
  margin-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  .el-date-editor .el-range__icon {
    line-height: 21px !important;
  }

  .top {
    margin-bottom: 10px;
  }
  .form {
    background-color: #fff;
    margin: 10px;
    padding: 10px 5px 0 5px;
    border: 1px solid #ececec;
    border-radius: 10px;
    box-shadow: 0px 0xp 10px #ccc;
  }
  .pagination {
    float: right;
  }
  .bgwhite {
    margin: 10px;
    padding: 10px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ececec;
    border-radius: 10px;
  }
  // .el-button {
  //   padding: 0 10px;
  // }
}

::v-deep .el-table--small th {
  padding: 0 !important;
}
</style>
